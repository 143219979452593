import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-site-footer',
  templateUrl: './sm-site-footer.component.html',
  styleUrls: ['./sm-site-footer.component.css']
})
export class SmSiteFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
