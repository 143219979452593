import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmHomePageComponent } from './sm-home-page/sm-home-page.component';
import { LoginComponent } from './login/login.component';
import { SmWiFiComponent as SmOfferingWiFiComponent } from './sm-offering-wifi/sm-offering-wifi.component';
import { SmOfferingComponent } from './sm-offering/sm-offering.component';
import { SmTendersComponent } from './sm-tenders/sm-tenders.component';
import { SmInfoComponent } from './sm-info/sm-info.component';
import { SmOfferingBb2oComponent } from './sm-offering-bb2o/sm-offering-bb2o.component';
import { SmOfferingBasicComponent } from './sm-offering-sat/sm-offering-sat.component';
import { SmOfferingXDslComponent } from './sm-offering-xdsl/sm-offering-xdsl.component';
import { SmOfferingBb2oBasicComponent } from './sm-offering-bb2o-basic/sm-offering-bb2o-basic.component';
import { SmOfferingBb2oFreeComponent } from './sm-offering-bb2o-free/sm-offering-bb2o-free.component';
import { SmOfferingBb2oTitaniumComponent } from './sm-offering-bb2o-titanium/sm-offering-bb2o-titanium.component';
import { SmOfferingXdslBronzeComponent } from './sm-offering-xdsl-bronze/sm-offering-xdsl-bronze.component';
import { SmOfferingXdslSilverComponent } from './sm-offering-xdsl-silver/sm-offering-xdsl-silver.component';
import { SmOfferingXdslGoldComponent } from './sm-offering-xdsl-gold/sm-offering-xdsl-gold.component';
import { SmOfferingXdslGoldPlusPlusComponent } from './sm-offering-xdsl-gold-plus-plus/sm-offering-xdsl-gold-plus-plus.component';
import { SmOfferingXdslPlatinumComponent } from './sm-offering-xdsl-platinum/sm-offering-xdsl-platinum.component';
import { SmOfferingBasicClassicComponent } from './sm-offering-basic-classic/sm-offering-basic-classic.component';
import { SmOfferingBasicLightComponent } from './sm-offering-basic-light/sm-offering-basic-light.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: SmHomePageComponent },
  { path: 'info', component: SmInfoComponent },
  { path: 'login', component: LoginComponent },
  { path: 'offerta/wifi', component: SmOfferingWiFiComponent },
  { path: 'offerta/bb2o', component: SmOfferingBb2oComponent },
  { path: 'offerta/bb2o/basic', component: SmOfferingBb2oBasicComponent },
  { path: 'offerta/bb2o/free', component: SmOfferingBb2oFreeComponent },
  { path: 'offerta/bb2o/titanium', component: SmOfferingBb2oTitaniumComponent },
  { path: 'offerta/basic', component: SmOfferingBasicComponent },
  { path: 'offerta/basic/light', component: SmOfferingBasicLightComponent },
  { path: 'offerta/basic/classic', component: SmOfferingBasicClassicComponent },
  { path: 'offerta/xdsl', component: SmOfferingXDslComponent },
  { path: 'offerta/xdsl/bronze', component: SmOfferingXdslBronzeComponent },
  { path: 'offerta/xdsl/silver', component: SmOfferingXdslSilverComponent },
  { path: 'offerta/xdsl/gold', component: SmOfferingXdslGoldComponent },
  {
    path: 'offerta/xdsl/goldplusplus',
    component: SmOfferingXdslGoldPlusPlusComponent
  },
  { path: 'offerta/xdsl/platinum', component: SmOfferingXdslPlatinumComponent },
  // { path: 'lis/wifi', component: SmOfferingLisWifiComponent },
  { path: 'offerta', component: SmOfferingComponent },
  { path: 'gare', component: SmTendersComponent },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
