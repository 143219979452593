import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-offering-xdsl-bronze',
  templateUrl: './sm-offering-xdsl-bronze.component.html',
  styleUrls: ['./sm-offering-xdsl-bronze.component.css']
})
export class SmOfferingXdslBronzeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
