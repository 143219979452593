import { Component, OnInit, AfterContentInit } from '@angular/core';
// import 'ol/ol.css';
// import { Map, View } from 'ol';
// import TileLayer from 'ol/layer/Tile';
// import Vector from 'ol/layer/Vector';
// import OSM from 'ol/source/OSM';
// import { fromLonLat } from 'ol/proj.js';

@Component({
  selector: 'app-sm-info',
  templateUrl: './sm-info.component.html',
  styleUrls: ['./sm-info.component.css']
})
export class SmInfoComponent implements OnInit, AfterContentInit {
  title = 'Sed Multitel S.r.l.';
  lat = 41.8737564;
  lng = 12.4774576;
  zoom = 17;

  constructor() { }

  ngOnInit() { }

  ngAfterContentInit(): void {
    // const map = new Map({
    //   target: 'map',
    //   layers: [
    //     new TileLayer({
    //       source: new OSM()
    //     })
    //   ],
    //   view: new View({
    //     center: fromLonLat([this.lng, this.lat]),
    //     zoom: this.zoom
    //   })
    // });
  }
}
