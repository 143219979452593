import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-offering-bb2o-titanium',
  templateUrl: './sm-offering-bb2o-titanium.component.html',
  styleUrls: ['./sm-offering-bb2o-titanium.component.css']
})
export class SmOfferingBb2oTitaniumComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
