import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SmSiteHeaderComponent } from './sm-site-header/sm-site-header.component';
import { SmSiteFooterComponent } from './sm-site-footer/sm-site-footer.component';
import { SmSiteNavigationBarComponent } from './sm-site-navigation-bar/sm-site-navigation-bar.component';
import { SmHomePageComponent } from './sm-home-page/sm-home-page.component';
import { LoginComponent } from './login/login.component';
import { SmWiFiComponent } from './sm-offering-wifi/sm-offering-wifi.component';
import { SmOfferingComponent } from './sm-offering/sm-offering.component';
import { SmTendersComponent } from './sm-tenders/sm-tenders.component';
import { SmInfoComponent } from './sm-info/sm-info.component';

import { AgmCoreModule } from '@agm/core';
import { SmOfferingBb2oComponent } from './sm-offering-bb2o/sm-offering-bb2o.component';
import { SmOfferingBasicComponent } from './sm-offering-sat/sm-offering-sat.component';
import { SmOfferingXDslComponent } from './sm-offering-xdsl/sm-offering-xdsl.component';
import { SmOfferingLisWifiComponent } from './sm-offering-lis-wifi/sm-offering-lis-wifi.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SmOfferingBb2oBasicComponent } from './sm-offering-bb2o-basic/sm-offering-bb2o-basic.component';
import { SmOfferingBb2oFreeComponent } from './sm-offering-bb2o-free/sm-offering-bb2o-free.component';
import { SmOfferingBb2oTitaniumComponent } from './sm-offering-bb2o-titanium/sm-offering-bb2o-titanium.component';
import { SmOfferingXdslBronzeComponent } from './sm-offering-xdsl-bronze/sm-offering-xdsl-bronze.component';
import { SmOfferingXdslSilverComponent } from './sm-offering-xdsl-silver/sm-offering-xdsl-silver.component';
import { SmOfferingXdslGoldComponent } from './sm-offering-xdsl-gold/sm-offering-xdsl-gold.component';
import { SmOfferingXdslGoldPlusPlusComponent } from './sm-offering-xdsl-gold-plus-plus/sm-offering-xdsl-gold-plus-plus.component';
import { SmOfferingXdslPlatinumComponent } from './sm-offering-xdsl-platinum/sm-offering-xdsl-platinum.component';
import { SmOfferingBasicClassicComponent } from './sm-offering-basic-classic/sm-offering-basic-classic.component';
import { SmOfferingBasicLightComponent } from './sm-offering-basic-light/sm-offering-basic-light.component';

@NgModule({
  declarations: [
    AppComponent,
    SmSiteHeaderComponent,
    SmSiteFooterComponent,
    SmSiteNavigationBarComponent,
    SmHomePageComponent,
    LoginComponent,
    SmWiFiComponent,
    SmOfferingComponent,
    SmTendersComponent,
    SmInfoComponent,
    SmOfferingBb2oComponent,
    SmOfferingBasicComponent,
    SmOfferingBasicLightComponent,
    SmOfferingBasicClassicComponent,
    SmOfferingXDslComponent,
    SmOfferingLisWifiComponent,
    SmOfferingBb2oBasicComponent,
    SmOfferingBb2oFreeComponent,
    SmOfferingBb2oTitaniumComponent,
    SmOfferingXdslBronzeComponent,
    SmOfferingXdslSilverComponent,
    SmOfferingXdslGoldComponent,
    SmOfferingXdslGoldPlusPlusComponent,
    SmOfferingXdslPlatinumComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AgmCoreModule.forRoot({}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
