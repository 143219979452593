import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-site-header',
  templateUrl: './sm-site-header.component.html',
  styleUrls: ['./sm-site-header.component.css']
})
export class SmSiteHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
