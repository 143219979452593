import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-offering-xdsl',
  templateUrl: './sm-offering-xdsl.component.html',
  styleUrls: ['./sm-offering-xdsl.component.css']
})
export class SmOfferingXDslComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
