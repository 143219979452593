import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-tenders',
  templateUrl: './sm-tenders.component.html',
  styleUrls: ['./sm-tenders.component.css']
})
export class SmTendersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
