import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-offering-wifi',
  templateUrl: './sm-offering-wifi.component.html',
  styleUrls: ['./sm-offering-wifi.component.css']
})
export class SmWiFiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
