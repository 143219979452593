import { Component, OnInit } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sm-offering',
  templateUrl: './sm-offering.component.html',
  styleUrls: ['./sm-offering.component.css'],
  providers: [NgbPopoverConfig]
})
export class SmOfferingComponent implements OnInit {
  constructor(config: NgbPopoverConfig) {
    config.placement = 'right';
    config.triggers = 'hover';
  }

  ngOnInit() {}
}
