import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sm-offering-basic-classic',
  templateUrl: './sm-offering-basic-classic.component.html',
  styleUrls: ['./sm-offering-basic-classic.component.css']
})
export class SmOfferingBasicClassicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
